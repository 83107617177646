// extracted by mini-css-extract-plugin
export var header = "W_M";
export var item = "W_bK";
export var itemImage = "W_cJ";
export var itemIsExternal = "W_cS";
export var itemIsInView = "W_cQ";
export var itemIsLinked = "W_cR";
export var itemText = "W_cK";
export var itemTime = "W_cT";
export var itemTimeIsBig = "W_cV";
export var logo = "W_bS";
export var root = "W_F";